import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout/Layout'

function Status() {
    return (
        <Layout>
            <section style={{width: 'min(90%, 1200px)', minHeight: '75vh', margin: 'auto'}}>
                <h1>Page status</h1>
                <p>The following list consists of the pages that have been created to date, and what their current status is:</p>
                <ul>
                    <li><Link to="/services">Services</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> In progress</li>
                    <ul>
                        <li><Link to="/services/osteopathy">Osteopathy</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> Ready for review</li>
                        <li><Link to="/services/sports-remedial-massage">Sports & Remedial Massage</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> Ready for review</li>
                        <li><Link to="/services/cranial-osteopathy">Cranial Osteopathy</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> Ready for review</li>
                    </ul>
                    <li><Link to="/about">About</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> In progress</li>
                    <li><Link to="/conditions-we-treat">Conditions we treat</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> Ready for review</li>
                    <ul>
                        <li><Link to="/conditions-we-treat/discomfort-in-advancing-years">Discomfort in advancing years</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> Ready for review</li>
                        <li><Link to="/conditions-we-treat/pregnancy-related-discomfort">Pregnancy related discomfort</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> Ready for review</li>
                        <li><Link to="/conditions-we-treat/postural-strain-at-work-and-driving">Postural strain at work and driving</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> Ready for review</li>
                        <li><Link to="/conditions-we-treat/sports-injury">Sports injury</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> Ready for review</li>
                        <li><Link to="/conditions-we-treat/back-pain">Back pain</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> Ready for review</li>
                    </ul>
                    <li><Link to="/faq">FAQ</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> Ready for review</li>
                    <li><Link to="/blog">Blog</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> TBC</li>
                    <li><Link to="/contact">Contact</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> Ready for review</li>
                    <li><Link to="/privacy-policy">Privacy policy</Link> - <strong>Design:</strong> Ready for review; <strong>Content:</strong> Ready for review</li>
                </ul>
            </section>
        </Layout>
    )
}

export default Status